<template>
  <div
    class="warp"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <div v-if="loading == false">
      <div class="items" id="pdfDom">
        <!-- :class="[item.id == 3 ? 'tests' : '']"  -->
        <div class="item" v-for="(item, index) in info" :key="item.id">
          <p>{{ item.shopName }}</p>
          <p>编号：{{ item.id }}</p>
          <p>券额：{{ item.amount }}元券</p>
          <p>有效期：{{ item.expirationTime }}</p>
          <el-image
            class="img"
            style="width: 125px"
            :src="hqh5QrcodeUrl(index)"
            :preview-src-list="[hqh5QrcodeUrl(index)]"
          >
            <template #fallback>
              <div class="image-fallback">图片加载失败</div>
            </template>
          </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2' // 引入qrcode
import { PhysicalCouponAll } from '@/api/coupon.js'
export default {
  data() {
    return {
      loading: true,
      info: [],
      query: {
        couponId: undefined,
      },
      list: [
        {
          id: 1,
          name: undefined,
          ticketNumber: undefined,
          quota: undefined,
          expirationTime: undefined,
        },
      ],
    }
  },
  created() {
    this.info = []
    if (this.$route.query.selectcouponsList) {
      this.printSelectedList()
    } else {
      // 打印全部
      this.getPhysicalCouponList()
    }
  },
  methods: {
    printSelectedList() {
      this.loading = true
      let setinfo = JSON.parse(this.$route.query.setinfo)
      let selectcouponsList = JSON.parse(this.$route.query.selectcouponsList)
      this.info = selectcouponsList.map((obj) => {
        let objs = {
          ...obj,
          shopName: setinfo.shopInfo.shopName,
          createTime: setinfo.createTime,
          validTime: setinfo.validTime,
          couponName: setinfo.couponName,
          discountValue: setinfo.discountValue,
          pkId: setinfo.pkId,
          couponType: setinfo.couponType,
          amount: setinfo.couponMoney,
          expirationTime: this.computDate(
            setinfo.createTime,
            setinfo.validTime
          ),
        }

        return objs
      })
      this.loading = false
    },
    hqh5QrcodeUrl(index) {
      let query = {
        pkid: this.info[index].pkId,
        couponKey: this.info[index].couponKey,
      }
      const newPath =
        process.env.VUE_APP_WEB_API +
        `/#/pages/scancouponspc/index?pkid=${query.pkid}&couponKey=${query.couponKey}`
      const qrCode = new QRCode(document.createElement('div'), {
        text: newPath,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.Q,
        version: 10,
      })
      let imageUrl = qrCode._el.firstChild.toDataURL()
      return imageUrl
    },
    getPhysicalCouponList() {
      this.loading = true
      this.query.couponId = this.$route.query.id
      PhysicalCouponAll(this.query)
        .then((res) => {
          if (res.data.code == 200) {
            // console.log(JSON.parse(this.$route.query.setinfo))
            let setinfo = JSON.parse(this.$route.query.setinfo)
            this.info = res.data.data.map((obj) => {
              let objs = {
                ...obj,
                shopName: setinfo.shopInfo.shopName,
                createTime: setinfo.createTime,
                validTime: setinfo.validTime,
                couponName: setinfo.couponName,
                discountValue: setinfo.discountValue,
                pkId: setinfo.pkId,
                couponType: setinfo.couponType,
                amount: setinfo.couponMoney,
                expirationTime: this.computDate(
                  setinfo.createTime,
                  setinfo.validTime
                ),
              }

              return objs
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 到期时间计算
    computDate(dateStr, day) {
      let str = this.conversionTime(dateStr)
      const num = day * 24 * 3600 * 1000
      const initiateDate = new Date(str)
      const time = initiateDate.getTime()
      var concludeDate = new Date(time + num)
      let y = concludeDate.getFullYear()
      let MM = concludeDate.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = concludeDate.getDate()
      d = d < 10 ? '0' + d : d
      let h = concludeDate.getHours()
      h = h < 10 ? '0' + h : h
      let m = concludeDate.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = concludeDate.getSeconds()
      s = s < 10 ? '0' + s : s
      const resu = y + '/' + MM + '/' + d + ' ' + h + ':' + m + ':' + s
      return resu
    },
    // 时间戳加密
    decimalToBase62(decimalNumber) {
      var characters =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      var base62Value = ''

      while (decimalNumber > 0) {
        var remainder = decimalNumber % 62
        base62Value = characters.charAt(remainder) + base62Value
        decimalNumber = Math.floor(decimalNumber / 62)
      }

      return base62Value
    },
  },
}
</script>
<style scoped lang="scss">
.warp {
  /* height: calc(100vh - 100px); */
  height: 100%;
  .items {
    height: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    .item {
      display: grid;
      place-items: center;
      &:nth-child(12n) {
        break-after: left;
      }
      .img {
        margin-top: 2px;
      }
      p {
        text-align: left;
        margin: 0;
        margin: 4px 0;
      }
    }
  }
}
</style>